:root {
  --main-color: #c36f3d;
  --white-color: #fff;
  --black-color: #000;
  --light-color1: #fdf9f7;
  --light-color2: #fbf4f0;
  --light-color3: #f7ede7;
  --light-color4: #f5e8e0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

a {
  color: rgba(0, 0, 0, 0.87);
}

body {
  background-color: var(--white-color);
}

@font-face {
  font-family: Gotham;
  src: url(../fonts/Gotham-Light.otf) format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: Gotham;
  src: url(../fonts/Gotham-Book.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url(../fonts/Gotham-Medium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url(../fonts/Gotham-Bold.otf) format('opentype');
  font-weight: 700;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 28px;
  padding-left: 240px;
  background-color: var(--light-color2);
}

.footer-mobile {
  position: relative;
  left: 0;
  bottom: 50px;
  right: 0;
  height: 28px;
  background-color: var(--light-color2);
}

.avatar {
  background-color: var(--white-color);
  color: var(--main-color);
}

.round-corner {
  border-radius: 8px !important;
}

.page-info-box {
  padding-left: 16px;
  padding-top: 8px;
}

.page-content {
  margin-left: -8px;
  margin-top: 12px;
  width: 100%;
}
@media screen and (max-width: 985px) {
  .page-content {
    max-width: 656px;
  }
}

.page-info-slider {
  /* padding-left: 8px; */
  /*width: 50%;*/
  aspect-ratio: 2341/1316;
  margin-top: 8px !important;
  margin-left: 16px !important;
  background-image: url(../images/Yt_bild_video_mallpsd.jpg); /* 2341 x 1316 */
  background-size: cover;
}

.opp-movie-btn {
  font-size: 0.75rem !important;
  background-color: #ffffff !important;
}

.opp-movie-btn:hover {
  background-color: #fbf4f0 !important;
}

.white-bg {
  background-color: var(--white-color);
}

.dialogText {
  font-size: 0.9rem !important;
}

.small-dg-header {
  font-size: 0.6rem !important;
  color: var(--black-color) !important;
  font-weight: bold !important;
}

.small-dg-text {
  font-size: 0.6rem !important;
  color: var(--black-color) !important;
}

.small-dg-text2 {
  font-size: 0.6rem !important;
  color: var(--main-color) !important;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.app-bar {
  border-bottom: 1px solid var(--light-color4);
}

.top-bar-text {
  color: var(--black-color) !important;
  font-size: 0.875rem !important;
}

.top-bar-text-names {
  color: var(--main-color);
}

.side-bar-text {
  color: var(--main-color);
  font-size: 1.9rem !important;
}

.side-bar-button {
  border: var(--main-color) solid 1px !important;
  border-radius: 1.4rem !important;
  padding-left: 0.7rem !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 0.5rem !important;
  min-height: 2.4rem;
  max-width: 242px;
}

.side-bar-icon {
  color: var(--main-color);
}

.side-bar-button:hover {
  background-color: var(--main-color) !important;
}

.side-bar-button:hover * {
  color: var(--white-color) !important;
}

.bottomBarPaper {
  position: fixed !important;
  width: 100% !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100 !important;
  border-top: 1px solid var(--light-color4) !important;
}

.bottomBar {
  position: sticky;
  bottom: 0;
}

.tabmenu {
  background-color: var(--light-color3);
}

.tabmenu-tabs a {
  font-weight: bold !important;
}

.investment-progress {
  margin-left: 8px;
  margin-top: 8px;
  padding: 5px 5px 5px 5px;
  background-color: var(--light-color1);
  color: var(--main-color) !important;
}

.investment-progress .title_text {
  font-size: 0.85em;
  color: var(--black-color) !important;
}

.investment-progress .total_text {
  font-size: 3em;
  margin-bottom: -8px;
}

.investment-progress .trend_text {
  font-size: 1.25em;
  margin-bottom: 4px;
}

.investment-progress .marketvalue_text {
  font-size: 1.25em;
  margin-bottom: -3px;
}

@media screen and (max-width: 600px) {
  .investment-progress .trend_text {
    /* font-size: 1.0em; */
    margin-bottom: 8px;
  }

  .investment-progress .marketvalue_text {
    /* font-size: 1.0em; */
  }
}

.investment-progress .break {
  margin-top: 2px;
  border-bottom: 1px solid var(--main-color);
  margin-bottom: 1em;
}

.portfolio-overview {
  max-width: 48em;
  color: var(--main-color);
  padding: 8px;
}

.portfolio-overview .text {
  font-size: 0.75rem;
}

.portfolio-overview .boldtext {
  font-size: 0.75rem;
  font-weight: bold;
}

.progress-graph-container {
  max-width: 64em;
}

.widget {
  min-width: 14em;
  color: var(--main-color);
  padding: 8px;
}

.info-table-widet {
  max-width: 64em;
  min-width: 14em;
  padding: 5px 1em 5px 1em;
  background-color: var(--white-color2);
  color: var(--main-color) !important;
  border: 1px solid var(--main-color);
  border-radius: 6px;
}

.info-table-widet .rows:not(:last-child) {
  border-bottom: 1px solid var(--light-color4);
}

.info-table-widet .rows:last-child {
  border-bottom: 0px;
}

.trade-order-header {
  font-weight: bold;
  font-size: 0.825rem;
}

.trade-order-details-box {
  border-bottom: 1px solid var(--light-color4);
}

.trade-order-details > p {
  color: var(--black-color);
}

.trade-order-item-box {
  border-bottom: 1px solid var(--light-color4);
  color: var(--black-color) !important;
}

.trade-order-item-box div p {
  color: var(--black-color) !important;
}

.infront {
  font-family: 'Gotham', sans-serif;
  font-size: 0.875rem;
}

.cell-overlay-root {
  font-family: 'Gotham', sans-serif;
  font-size: 0.875rem;
}

.investment-table {
  background-color: var(--light-color1);
}

.investment-table-row {
}

.investment-table-row:hover {
  background-color: var(--light-color3);
}

.investment-table-delete {
  font-weight: bold;
  font-size: 0.75rem;
}

.investment-table-text {
  font-size: 0.75rem !important;
}

.investment-table-delete:hover {
  background-color: var(--light-color4);
  cursor: pointer;
  font-weight: bold;
}

.board-image-box {
  margin-top: 8px;
  margin-left: 8px;
  max-width: 48em;
}

.board-image {
  border-radius: 8px !important;
  max-width: 48em;
}

.news-notitle .cell-h4 {
  display: none;
}

.highcharts-credits {
  display: none;
}

.agreement-table-button {
  font-size: 0.75rem !important;
}

@media screen and (max-width: 600px) {
  .agreement-table-button {
    font-size: 0.6rem !important;
  }
}

.position-link {
  text-decoration: none !important;
  color: var(--black-color) !important;
}

.position-link:hover {
  text-decoration: underline !important;
}

.MuiDataGrid-pinnedRows--bottom {
  background-color: var(--light-color4) !important;
}

.MuiDataGrid-pinnedRows--bottom {
  background-color: var(--light-color4) !important;
  & .MuiTypography-root {
    font-weight: bold;
  }
}

.securityDetails-Large {
  padding: 8px !important;
}

.securityDetails-Medium {
  padding: 1px !important;
}

.securityDetails-Small {
  padding: 0px !important;
}

.downloadBtn-Large {
}

.downloadBtn-Medium {
  font-size: 0.6rem !important;
}

.downloadBtn-Small {
  font-size: 0.6rem !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 8px !important;
  padding-right: 6px !important;
}

.document-name {
  font-size: 0.8125rem !important;
  text-align: left !important;
}
@media screen and (max-width: 600px) {
  .document-name {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 400px) {
  .document-name {
    font-size: 0.6rem !important;
  }
}

.btn-small {
  text-transform: none !important;
  font-size: 0.8em !important;
  font-weight: normal !important;
  padding: 3px 4px 3px 5px !important;
  border: none !important;
  min-width: 32px !important;
  vertical-align: text-bottom !important;
  line-height: normal !important;
  border-radius: 0 !important;
  background-color: var(--light-color1) !important;
}

.btn-small:hover {
  background-color: var(--light-color3) !important;
}

.btn-small-selected {
  background-color: var(--light-color4) !important;
  font-weight: bold !important;
}

.cookie-link {
  font-family: 'Gotham', sans-serif;
  font-size: 0.875rem;
}

.errorMessage {
  margin-left: 8px !important;
  color: red !important;
}
